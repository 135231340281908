<template>
    <div v-if="show_select_points" style="max-width: 1800px" class="PanelProceeds mx-auto">
        <v-card class="ma-3 elevation-6 d-flex flex-column">
            <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                <v-toolbar-title class="overline">Упущенный спрос</v-toolbar-title>
            </v-toolbar>

            <v-row class="pa-5 pb-0">
                <v-col cols="12">
                    <v-autocomplete
                        v-model="selected_points"
                        :items="filter_points"
                        item-text="name"
                        item-value="idString"
                        :menu-props="{ maxHeight: '400' }"
                        label="Выберите точку/точки"
                        multiple
                        chips
                        deletable-chips
                        clearable
                        :search-input.sync="searchInput"
                        @change="setPoints"
                    >
                        <template v-slot:selection="{ item, index }">
                            <span v-if="isSelectedAllPoints && index === 0" class="grey--text">
                                Выбраны все точки
                            </span>
                            <v-chip v-if="!isSelectedAllPoints && index <= show_max_points - 1" close class="ma-2" @click:close="() => handleRemoveItem(index)">
                                <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="!isSelectedAllPoints && index === show_max_points" class="grey--text text-caption">
                                (+{{ selected_points.length - show_max_points }} точек ещё)
                            </span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="pl-5 pr-5">
                <v-col cols="12">
                    <Bar :chartData="chartData" :options="options" />
                    <v-progress-linear v-show="bar_loading_show" indeterminate color="indigo"></v-progress-linear>
                </v-col>
            </v-row>
            <v-row class="pl-5 pr-5">
                <v-col cols="12" xl="5">
                    <v-row>
                        <v-col cols="8">
                            <p class="pb-1">По времени:</p>
                            <v-select :items="time_interval" v-model="time_set" label="Время" solo @change="setPreset">
                            </v-select>
                        </v-col>
                        <v-col cols="4">
                            <p class="pb-1">Охват:</p>
                            <v-select :items="deep_list" @change="get_Chart" v-model="deep_selected" item-text="name"
                                item-value="deep" item-disabled="customDisabled" class="ma-0 pa-0" persistent-hint
                                return-object single-line solo></v-select>
                        </v-col>
                    </v-row>

                    <div v-show="show_calendar" class="pb-5">
                        <template>
                            <v-date-picker range v-model="dates_calendar" locale="ru-ru"
                                selected-items-text="Выбран период" :first-day-of-week="1" :elevation="6" full-width
                                @change="setPreset()"> </v-date-picker>
                        </template>
                    </div>
                </v-col>
                <v-col cols="12" xl="7">
                    <v-layout class="grey lighten-5 subheading text-center justify-center"
                        align-center fill-height column>
                        <p class="ma-0 pa-0">Нет заказов за выбранный период</p>
                        <p class="ma-0 pa-0">
                            C <b>{{ getHumanStrDate(time_start) }}</b> по <b>{{ getHumanStrDate(time_end) }}</b>
                            <!-- ${dateData.date} ${dateData.month_of_year}, ${dateData.day_of_week} -->
                        </p>
                        <p class="ma-0 pa-0">
                            Количество дней <b>{{ count_day }}</b>
                        </p>
                    </v-layout>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import Bar from './Bar.js'
    import DateTime from '../../mixins/DateTime.js'

    export default {
        mixins: [DateTime],
        data() {
            return {
                show_max_points: 5,
                searchInput: '',
                role: false,
                name_chart_cancelled: 'Отменённые заказы',
                name_chart_not_cancelled: 'Неотменённые заказы',
                filter_points: [],
                selected_points: [],
                chart_labels: [],
                chart_dates_array: [],
                chart_cancelled_orders: [],
                chart_not_cancelled_orders: [],
                deep_selected: { name: 'дневная', deep: 'days' },
                deep_list: [
                    { name: 'Месячный', deep: 'months', customDisabled: false }, // 0
                    { name: 'Дневной', deep: 'days', customDisabled: false }, // 1
                    { name: 'Часовой', deep: 'hours', customDisabled: false }, // 2
                ],

                time_start: null,
                time_end: null,
                count_day: 0,
                time_now: new Date(Date.now()),
                time_now_str: null,

                time_interval: ['Произвольный период', 'Сегодня', 'Вчера', 'Позавчера', '7 дней', '30 дней', '90 дней', '365 дней'],
                time_set: 'Сегодня',

                show_calendar: false,
                show_select_points: false,
                bar_loading_show: false,

                dates_calendar: [],

                options: {
                    tooltips: {
                        callbacks: {
                            title(tooltipItem, data) {
                                const time = data.datasets[tooltipItem[0].datasetIndex].dates_array[tooltipItem[0].index]
                                const multistringText = []

                                const beautiful_hour_labels = (hour) => {
                                    const hour_str = ('0' + `${hour}`).slice(-2)

                                    return `${hour_str}:00`
                                }

                                if ('hour' in time) {
                                    multistringText.push(`Дата: ${time.date} ${time.month_of_year} ${time.year}, ${time.day_of_week}`)
                                    multistringText.push(`Время: ${beautiful_hour_labels(time.hour)}`)
                                } else if ('date' in time) {
                                    multistringText.push(`Дата: ${time.date} ${time.month_of_year} ${time.year}, ${time.day_of_week}`)
                                } else {
                                    multistringText.push(`Дата: ${time.month_of_year} ${time.year}`)
                                }

                                return multistringText
                            },

                            label(tooltipItem, data) {
                                const multistringText = []

                                const cancelled_orders_count = data.datasets[0].data[tooltipItem.index]
                                const not_cancelled_orders_count = data.datasets[1].data[tooltipItem.index]

                                const sum = cancelled_orders_count + not_cancelled_orders_count

                                let percent_cancelled = Math.round((100 * cancelled_orders_count) / sum)
                                let percent_not_cancelled = Math.round((100 * not_cancelled_orders_count) / sum)

                                if (!percent_cancelled || isNaN(percent_cancelled)) percent_cancelled = 0
                                if (!percent_not_cancelled || isNaN(percent_not_cancelled)) percent_not_cancelled = 0

                                multistringText.push(` `)

                                multistringText.push(`🟩 Неотменённые заказы: ${not_cancelled_orders_count}`)
                                multistringText.push(`Процент: ${percent_not_cancelled}%`)

                                multistringText.push(` `)

                                multistringText.push(`🟥 Отменённые заказы: ${cancelled_orders_count}`)
                                multistringText.push(`Процент: ${percent_cancelled}%`)

                                return multistringText
                            },
                        },
                        backgroundColor: '#FFF',
                        titleFontSize: 16,
                        titleFontColor: '#3f51b5',
                        bodyFontColor: '#666666',
                        // bodyFontSize: 14,
                        displayColors: false,
                        borderColor: '#3f51b5',
                        borderWidth: 2,
                        bodySpacing: 5,
                    },
                    plugins: {
                        labels: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                categoryPercentage: 0.5,
                                barPercentage: 1,
                            },
                        ],
                        yAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    callback(label, index, labels) {
                                        return new Intl.NumberFormat('ru-RU').format(Number(label))
                                    },
                                },
                            },
                        ],
                    },
                },
                chartData: {},
            }
        },

        components: {
            Bar,
        },

        computed: {
            isSelectedAllPoints() {
                return this.selected_points.length === this.filter_points.length
            }
        },

        mounted() {
            this.setSetting()
        },

        methods: {
            setSetting() {
                this.time_now = new Date(Date.now())
                this.time_now_str = this.getStrDate(this.time_now)
                this.time_start = this.time_now_str
                this.time_end = this.time_now_str
                this.role = this.$store.state.user.role
                switch (this.role) {
                    case 1: //operator
                        this.show_select_points = true
                        this.getPoints()
                        break
                    case 3: //point
                        this.show_select_points = false
                        this.setPreset()
                        break
                    default:
                        break
                }
            },
            setRestrictionsDeep() {
                const Date1 = new Date(this.time_start)
                const Date2 = new Date(this.time_end)
                this.count_day = Math.floor((Date2.getTime() - Date1.getTime()) / (1000 * 60 * 60 * 24)) + 1

                if (this.count_day > 31) {
                    if (this.deep_selected.deep == 'hours') {
                        this.deep_selected = this.deep_list[1]
                    }

                    this.deep_list[2].customDisabled = true
                } else {
                    this.deep_list[2].customDisabled = false
                }
            },
            setPreset() {
                switch (this.time_set) {
                    case 'Сегодня':
                        this.time_start = this.time_now_str
                        this.time_end = this.time_now_str
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[2]
                        break
                    case 'Вчера':
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 1))
                        this.time_end = this.time_start
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[2]
                        break
                    case 'Позавчера':
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 2))
                        this.time_end = this.time_start
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[2]
                        break
                    case '7 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 6))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[1]
                        break
                    case '30 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setDate(this.time_now.getDate() - 29))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[1]
                        break
                    case '90 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setMonth(this.time_now.getMonth() + 1 - 3))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[0]
                        break
                    case '365 дней':
                        this.time_end = this.time_now_str
                        this.time_start = this.getStrDate(new Date().setMonth(this.time_now.getMonth() + 1 - 12))
                        this.show_calendar = false
                        this.deep_selected = this.deep_list[0]
                        break
                    case 'Произвольный период':
                        this.show_calendar = true

                        this.dates_calendar.sort()
                        if (this.dates_calendar.length == 2) {
                            this.time_end = this.dates_calendar[1]
                            this.time_start = this.dates_calendar[0]
                        }
                        if (this.time_end == this.time_start && this.dates_calendar.length == 2) {
                            this.deep_selected = this.deep_list[2]
                        } else {
                            this.deep_selected = this.deep_list[1]
                        }

                        break
                }
                this.setRestrictionsDeep()
                this.get_Chart()
            },

            setPoints(data) {
                this.searchInput = ''
                this.selected_points = data
                this.get_Chart()
            },

            getStrDate(date) {
                date = new Date(date)
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            },

            getHumanStrDate(date) {
                date = new Date(date)
                // console.log('date', date)
                // console.log('toLocaleDateString', date.toLocaleDateString())
                // return `${ date.getDate() } ${ date.getMonth() } ${ date.getFullYear() }`;
                return date.toLocaleDateString()
            },

            getPoints() {
                this.$store.dispatch('points_get').then(res => {
                    this.filter_points = res.data.points.map(p => ({
                        id: p.idString,
                        name: p.name,
                    }))
                    this.filter_points = res.data.points
                    this.selected_points = this.filter_points.map((point) => point.idString)
                    if (this.filter_points.length != 0) {
                        this.setPreset()
                    }
                })
            },

            beautiful_hour_labels(hour) {
                const hour_str = ('0' + `${hour}`).slice(-2)

                return `${hour_str}:00`
            },

            get_Chart() {
                this.bar_loading_show = true
                this.$store.dispatch('get_cancelled_orders_analytics', {
                    points_id: this.selected_points,
                    time_start: this.time_start,
                    time_end: this.time_end,
                    deep: this.deep_selected.deep
                }).then(({ data: { labels = [], dates_array = [], cancelled = [], notCancelled = [] } }) => {
                    this.chart_labels = labels
                    this.chart_dates_array = dates_array
                    this.chart_cancelled_orders = cancelled
                    this.chart_not_cancelled_orders = notCancelled

                    const labels_bar = []

                    this.chart_dates_array.forEach((dateData) => {
                        if ('hour' in dateData) {
                            if (dateData.hour == 0) {
                                labels_bar.push(`${dateData.date} ${dateData.month_of_year}, ${dateData.day_of_week}`)
                            } else {
                                labels_bar.push(`${this.beautiful_hour_labels(dateData.hour)}`)
                            }

                        } else if ('date' in dateData) {
                            if (dateData.year == this.time_now.getFullYear()) {
                                labels_bar.push(`${dateData.date} ${dateData.month_of_year}, ${dateData.day_of_week}`)
                            } else {
                                labels_bar.push(`${dateData.date} ${dateData.month_of_year} ${String(dateData.year)}, ${dateData.day_of_week}`)
                            }
                        } else {
                            if (dateData.year == this.time_now.getFullYear()) {
                                labels_bar.push(`${dateData.month_of_year}`)
                            } else {
                                labels_bar.push(`${dateData.month_of_year} ${dateData.year}`)
                            }
                        }
                    })

                    this.chartData = {
                        // TODO: WTF?
                        labels: labels_bar || this.chart_labels,
                        datasets: [
                            {
                                label: this.name_chart_cancelled,
                                backgroundColor: '#dc3546',
                                data: this.chart_cancelled_orders,
                                dates_array: this.chart_dates_array,
                            },
                            {
                                label: this.name_chart_not_cancelled,
                                backgroundColor: '#4caf50',
                                data: this.chart_not_cancelled_orders,
                                dates_array: this.chart_dates_array,
                            },
                        ],
                    }
                }).finally(() => this.bar_loading_show = false)
            },

            handleRemoveItem(index) {
                this.selected_points.splice(index, 1)
            }
        }
    }
</script>

<style lang="stylus" scoped></style>
