<template>
    <div class="analytics">

        <v-card elevation="3" class="select-mode pa-5 mx-5 my-3" v-if="this.$store.state.isAnalyticFilterPopup">
            <v-card-title class="pa-1">Аналитика</v-card-title>
            <!-- <v-row class="align-end ma-0" v-if="this.$store.state.settings.services_enable">
                <v-checkbox  v-model="showServicesOrder" hide-details class="mt-2">
                </v-checkbox>
                <div>Для сервисов</div>
            </v-row>
            <v-row class="align-end ma-0" >
                <v-checkbox v-model="showCouriersOrder" hide-details class="mt-2">
                </v-checkbox>
                <div>Для курьеров</div>
            </v-row> -->
        </v-card>
        <v-row style="max-width: 1200px" class="mx-auto">
            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardPoint&&showCouriersOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#2196F3" dark @click="goToLink('orders', true)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Заказы</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-inbox-multiple</v-icon> {{ ordersCountToday }} </v-list-item-title>
                                </template>
                                <span>Все заказы</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ ordersCountComplete }} </span>
                                </template>
                                <span>Доставленные заказы</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="showServicesOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#cccc56" dark @click="goToLink('service_orders', true)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Услуги</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-hammer-screwdriver</v-icon> {{ serviceOrdersCountToday }} </v-list-item-title>
                                </template>
                                <span>Все заказы по услугам</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ serviceOrdersCountComplete }} </span>
                                </template>
                                <span>Выполненные услуги</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardOperator&&showCouriersOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#DC3546" dark @click="goToLink('points', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Точки</div>
                            <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on"><v-icon size="large">mdi-home-group</v-icon> {{ points.length }}</div>
                                    </template>
                                    <span>Всего точек</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on"><v-icon class="mr-1 ml-3" size="large">mdi-account-multiple</v-icon> {{ point_users.length }}</div>
                                    </template>
                                    <span>Всего менеджеров онлайн</span>
                                </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ pointsTodayCount }} </span>
                                </template>
                                <span>Точек в работе</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardPoint&&showCouriersOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#4CAF50" dark @click="goToLink('couriers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Курьеры</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-car-multiple</v-icon> {{ couriers.length }} </v-list-item-title>
                                </template>
                                <span>Всего курьеров онлайн</span>
                            </v-tooltip>
                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ couriersTodayCount }} </span>
                                </template>
                                <span>{{ userIsPoint ? 'Курьеров на точке' : 'Курьеров в работе' }}</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardPoint&&showCouriersOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#FF9800" dark @click="goToLink('couriers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">{{ interfaceFeeValue }}</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-cash-multiple</v-icon> {{ profitToday }} ₽ </v-list-item-title>
                                </template>
                                <span>{{ interfaceFeeValue }} всего за сегодня</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> {{ profitDiff }} ₽ </span>
                                </template>
                                <span>{{ interfaceFeeValue }} по сравнению с предыдущим днем</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            
            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardPoint&&showCouriersOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="indigo darken-6" dark @click="goToLink('couriers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Цена доставки</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-truck-fast</v-icon> {{ interfaceAveragePriceDeliveryMy || 0 }} ₽</v-list-item-title>
                                </template>
                                <span>Средняя цена доставки</span>
                            </v-tooltip>
                            <v-list-item-subtitle v-show="$store.state.user.role != 1">весь сервис: {{ interfaceAveragePriceDeliveryAll || 0 }} ₽</v-list-item-subtitle>
                            <v-list-item-subtitle v-show="$store.state.user.role == 1"><wbr></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ interfaceAveragePriceDeliveryMyCount || 0 }} </span>
                                </template>
                                <span>Доставленные заказы, на основе которых определена средняя цена, шт.</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardOperator&&showServicesOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#f0c" dark @click="goToLink('services', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Сервисы</div>
                            <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on"><v-icon size="large">mdi-cogs</v-icon> {{ services.length }}</div>
                                    </template>
                                    <span>Всего сервисов</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on"><v-icon class="mr-1 ml-3" size="large">mdi-account-cog</v-icon> {{ service_users.length }}</div>
                                    </template>
                                    <span>Всего операторов онлайн</span>
                                </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ servicesTodayCount }} </span>
                                </template>
                                <span>Сервисов в работе</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardService&&showServicesOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#cc00ff" dark @click="goToLink('workers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">Сервисмены</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-account-hard-hat</v-icon> {{ workers.length }} </v-list-item-title>
                                </template>
                                <span>Всего сервисменов онлайн</span>
                            </v-tooltip>
                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> <v-icon size="large">mdi-check-bold</v-icon> {{ workersTodayCount }} </span>
                                </template>
                                <span>Сервисменов в работе</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
      

            <v-col cols="12" :sm="interfaceCardSm" :md="interfaceCardMd" v-if="interfaceCardService&&showServicesOrder">
                <v-card class="info-card mx-auto elevation-6" max-width="240" color="#335" dark @click="goToLink('workers', false)">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline">ЗП сервисов</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1 d-flex align-center" v-bind="attrs" v-on="on"> <v-icon size="large" class="mr-1">mdi-hand-coin</v-icon> {{ serviceProfitToday }} ₽ </v-list-item-title>
                                </template>
                                <span>{{ interfaceFeeValue }} всего за сегодня</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="60" class="mb-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on"> {{ serviceProfitDiff }} ₽ </span>
                                </template>
                                <span>{{ interfaceFeeValue }} по сравнению с предыдущим днем</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="loaded&&showCouriersOrder" style="max-width: 1200px" class="mx-auto">
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg" v-if="interfaceOverviewPoint">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по статусам</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :centerLabel="{ text: getOrdersCountPercent(), title: 'Процент доставленных заказов к общему количеству' }" :chartData="chartDataOrders" :colors="orderStatusColorsHtml()" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg" v-if="interfaceOverviewOperator">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по точкам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataPoints" :colors="colorsHtml" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg" v-if="interfaceOverviewPoint">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по курьерам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataCouriers" :colors="colorsHtml" />
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="loaded&&showServicesOrder" style="max-width: 1200px" class="mx-auto d-flex justify-center mb-8">
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор услуг по статусам</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :centerLabel="{ text: getServicesCountPercent(), title: 'Процент выполненных услуг к общему кол-ву' }" :chartData="chartDataServiceOrders" :colors="orderStatusColorsHtml(true)" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg" v-if="interfaceOverviewOperator">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор услуг по точкам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataServicePoints" :colors="colorsHtml" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="interfaceOverviewMd" :lg="interfaceOverviewLg">
                <v-card align="center" justify="center" class="overview mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор услуг по сервисменам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataWorkers" :colors="colorsHtml" />
                </v-card>
            </v-col>
        </v-row>

        <v-row style="max-width: 1800px" class="mx-auto" v-if="showCouriersOrder&&(userIsPoint||userIsOperator)">
            <v-col cols="12">
                <v-card class="mx-auto pb-3 elevation-6">
                    <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Последние 30 заказов</v-toolbar-title>
                    </v-toolbar>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Сист.</th>
                                    <th v-if="$store.state.settings.marketingSourceView" class="text-left">Источник</th>
                                    <th class="text-left">Название</th>
                                    <th class="text-left">Статус</th>
                                    <th class="text-left">Гость</th>
                                    <th class="text-left">Адрес доставки</th>
                                    <th v-if="userIsPoint" class="text-left">Цена</th>
                                    <th v-if="!userIsPoint" class="text-left">Цена курьера</th>
                                    <th v-if="!userIsPoint" class="text-left">Цена точки</th>
                                    <th class="text-left">Курьер</th>
                                    <th class="text-left">Готов</th>
                                    <th class="text-left">Забрать</th>
                                    <th class="text-left">Доставить</th>
                                    <th class="text-left">Забран</th>
                                    <th class="text-left">Доставлен</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="item in [...orders, ...multiOrders].slice(0, 30)" :key="item.id" :style="isCombinedOrder(item) ? 'background-color: rgb(251, 230, 255);' : 'background-color: white;'">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-avatar size="24">
                                                    <img alt="source" :src="iconSource(item)" />
                                                </v-avatar>
                                            </td>
                                        </template>
                                        <span>{{ tooltipSource(item) }}</span>
                                    </v-tooltip>

                                    <tdFade v-if="$store.state.settings.marketingSourceView" :data="item.options.marketingSource ? item.options.marketingSource : ''" />

                                    <tdFade bold :data="item.name" />

                                    <td>
                                        <v-chip :color="orderStatusColorHtml(item.status)" text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                            {{ orderStatusStr(item.status) }}
                                        </v-chip>
                                    </td>

                                    <!-- <template v-slot:[`item.guest`]="{ item }">
                                        <span style="min-width: 170px">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <td v-bind="attrs" v-on="on">
                                                        
                                                        <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">
                                                            <span class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                                        </router-link>

                                                        <span v-else class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>

                                                    </td>
                                                </template>
                                                <span>{{ getGuestStr(item.guest) }}</span>
                                            </v-tooltip>
                                            <v-btn class="ml-2" v-if="!item.guest_id && item.status != config.order.status.forming" outlined tile text x-small @click="onClickGuestCreate(item)"><v-icon small>mdi-account-plus-outline</v-icon></v-btn>
                                        </span>
                                    </template> -->

                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on"> -->

                                    <!-- <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">

                                                    <span class="py-2 tooltip-text" :class="item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>

                                                </router-link>


                                                <span v-else class="py-2 tooltip-text" :class="item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span> -->

                                    <!-- <tdFade v-if="item.guest_phone" class="py-2 tooltip-text guest-selectable" @click="onClickGuest(item)" :data="getGuestPhoneName(item)" /> -->

                                    <!-- <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">
                                                    <span class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                                </router-link> -->

                                    <!-- <span v-else class="py-2 tooltip-text guest-error" :class="item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'">{{ getGuestPhoneName(item) }}</span> -->

                                    <!-- </td>
                                        </template>
                                        <span>{{ getGuestStr(item.guest) }}</span>
                                    </v-tooltip> -->

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <tdFade :data="getGuestStr(item.guest).substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ getGuestStr(item.guest) }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                                <tdFade :data="item.finish_addr.substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ item.finish_addr }}</span>
                                    </v-tooltip>

                                    <td v-if="userIsPoint" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                        <tdFade :data="+item.fee_point" />
                                    </td>

                                    <td v-if="!userIsPoint" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                        <tdFade :data="+item.fee_courier" />
                                    </td>

                                    <td v-if="!userIsPoint" :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                        <tdFade :data="+item.fee_point" />
                                    </td>

                                    <td class="d-flex align-center">
                                        <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                            <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.courier_tid}`" />
                                        </v-avatar>
                                        <tdFade v-if="userIsPoint" bold :data="item.courier_name.split(' ')[0]" />
                                        <tdFade v-else bold :data="item.courier_name" />
                                    </td>

                                    <tdFade :data="item.mode.split(' ')[0] + ' ' + timeToString(item.date)" />

                                    <tdFade :data="timeToString(item.date_ready)" />

                                    <tdFade :data="timeToString(item.date_delivery)" />

                                    <td>
                                        <span class="mr-2">{{ timeToString(item.date_take) }}</span>
                                        <v-chip v-if="item.date_take" class="px-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                            {{ timeDiff(item.date_take, item.date_ready) }}
                                        </v-chip>
                                    </td>

                                    <td>
                                        <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                                        <v-chip v-if="item.date_complete" class="px-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                            {{ timeDiff(item.date_complete, item.date_delivery) }}
                                        </v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <span v-if="!orders.length" class="no-data"> Нет данных </span>
                </v-card>
            </v-col>
        </v-row>

        <v-row style="max-width: 1800px" class="mx-auto" v-if="showServicesOrder&&(userIsService||userIsOperator)">
            <v-col cols="12">
                <v-card class="mx-auto pb-3 elevation-6">
                    <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Последние 30 услуг</v-toolbar-title>
                    </v-toolbar>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Сист.</th>
                                   
                                    <th class="text-left">Название</th>
                                    <th class="text-left">Статус</th>
                                    <th class="text-left">Адрес заказа</th>
                                    <th class="text-left">Заказчик</th>
                                    
                                    <th class="text-left">Сервис</th>
                                    <th class="text-left">Сервисмен</th>
                                    <th class="text-left">Цена заказа</th>
                                    <th class="text-left">% Комиссия</th>
                                    <th class="text-left">К выплате сервисмену</th>
                                    <th class="text-left">Время</th>
                                    <th class="text-left">Принят</th>
                                    <th class="text-left">Выполнен</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="item in service_orders.slice(0, 30)" :key="item.id">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-avatar size="24">
                                                    <img alt="source" :src="iconSource(item)" />
                                                </v-avatar>
                                            </td>
                                        </template>
                                        <span>{{ tooltipSource(item) }}</span>
                                    </v-tooltip>

                                    <tdFade v-if="$store.state.settings.marketingSourceView" :data="item.options.marketingSource ? item.options.marketingSource : ''" />

                                    <tdFade bold :data="item.name" />

                                    <td>
                                        <v-chip :color="orderStatusColorHtml(item.status)" text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                            {{ orderStatusStr(item.status,true) }}
                                        </v-chip>
                                    </td>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <tdFade :data="item.finish_addr.substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ item.finish_addr }}</span>
                                    </v-tooltip>

                                    <tdFade :data="points.length?points.find(p=>p.id==item.point_id).name:''" />
                                    <tdFade :data="item.service_name" />
                                  
                                  

                                    <td class="d-flex align-center">
                                        <v-avatar size="24" class="mr-2" v-if="item.worker_name != '--'">
                                            <img alt="worker" :src="`${config.api.delivery + 'user_avatar?tid=' + item.worker_tid}`" />
                                        </v-avatar>
                                        <tdFade bold :data="item.worker_name" />
                                    </td>

                                    <tdFade :data="Number(item.pay_cost)" />

                                    <tdFade :data="item.percent+'%'" />

                                    <td><span>
                                        {{item.fee_worker}}
                                    </span></td>

                                    <tdFade :data="timeToString(item.date)" />

                                    <td>
                                        <span class="mr-2">{{ timeToString(item.date_accept) }}</span>
                                        <v-chip v-if="item.date_accept" class="px-1" :color="timeColorHtml(item.date, item.date_accept)" x-small label text-color="white">
                                            {{ timeDiff(item.date, item.date_accept) }}
                                        </v-chip>
                                    </td>

                                    <td>
                                        <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                                        <v-chip v-if="item.date_complete" class="px-1" :color="timeColorHtml(item.date_accept, item.date_complete)" x-small label text-color="white">
                                            {{ timeDiff(item.date_accept, item.date_complete) }}
                                        </v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <span v-if="!orders.length" class="no-data"> Нет данных </span>
                </v-card>
            </v-col>
        </v-row>

        <Proceeds v-if="showCouriersOrder&&(userIsPoint||userIsOperator)" />
        <ProceedsService v-if="showServicesOrder&&(userIsService||userIsOperator)" />
        <CancelledOrders v-if="userIsService||userIsOperator" />
        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import Donut from './Donut.vue'
    import TdFade from './TdFade.vue'
    import Proceeds from './Proceeds.vue'
    import ProceedsService from './ProceedsService.vue'
    import CancelledOrders from './CancelledOrdersRatio.vue'

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Notify from '../../mixins/Notify.js'

    export default {
        mixins: [DateTime, Status, Notify],
        data() {
            return {
                config,
                loaded: false,
                chartDataOrders: {},
                chartDataPoints: {},
                chartDataCouriers: {},
                chartDataWorkers: {},
                chartDataServicePoints: {},
                chartDataServiceOrders: {},
                ordersCountToday: '--',
                ordersCountComplete: '--',
                pointsTodayCount: '--',
                servicesTodayCount: '--',
                couriersTodayCount: '--',
                workersTodayCount: '--',
                serviceOrdersCountToday: '--',
                serviceOrdersCountComplete: '--',

                orders: [],
                combined_orders: [],
                service_orders: [],
                points: [],
                couriers: [],
                workers:[],
                point_users: [],
                services:[],
                service_users:[],
                users: [],
                pointsToday: [],
                pointsTodayCounts: [],
                pointsServiceToday:[],
                pointsServiceTodayCounts:[],
                servicesToday:[],//
                servicesTodayCounts:[],//
                workersToday:[],
                workersTodayCounts:[],
                couriersToday: [],
                couriersTodayCounts: [],
                couriersSelected: [],
                labelsSelected: [],
                profitToday: 0,
                profitYesterday: 0,
                profitDiff: 0,
                serviceProfitToday: 0,
                serviceProfitYesterday: 0,
                serviceProfitDiff: 0,
                interfaceFeeValue: true,
                interfaceCardOperator: true,
                interfaceCardPoint:true,
                interfaceCardService:true,
                interfaceCardMd: 3,
                interfaceCardSm: 6,
                interfaceOverviewOperator: true,
                interfaceOverviewPoint: true,
                interfaceOverviewMd: 6,
                interfaceOverviewLg: 4,
                interfaceAveragePriceDeliveryMy: 0,
                interfaceAveragePriceDeliveryMyCount:0,
                interfaceAveragePriceDeliveryAll: 0,

                orderStatuses: [],
                serviceOrderStatuses:[],
                showCouriersOrder:true,
                showServicesOrder:this.$store.state.settings.services_enable,
                timer: null,
                userIsPoint: false,
                userIsService:false,
                userIsOperator:false,
                loading: false,
                multiOrders: [],
            }
        },
        components: {
            Donut,
            TdFade,
            Proceeds,
            ProceedsService,
            CancelledOrders
        },
        mounted() {
            console.log('!!! mounted !!!')
            // console.log('NOW - ', moment().format('YYYY-MM-DD'))

            this.userIsOperator = this.$store.state.user.role == config.user.role.operator
            this.userIsPoint = this.$store.state.user.role == config.user.role.point
            this.userIsService = this.$store.state.user.role == config.user.role.service

        
            if (this.$store.state.user.role == config.user.role.operator) {
                // this.interfaceFeeValue = 'Выручка'
                this.interfaceFeeValue = 'Зарплата'

                this.interfaceCardOperator = true
                this.interfaceCardMd = 4
                this.interfaceCardSm = 6

                this.interfaceOverviewOperator = true
                this.interfaceOverviewMd = 6
                this.interfaceOverviewLg = 4
            } else if(this.$store.state.user.role == config.user.role.point){
                this.interfaceFeeValue = 'Расходы'
                // this.interfaceFeeValue = 'Зарплата'

                this.interfaceCardOperator = false
                this.interfaceCardService = false
                this.interfaceCardMd = 6
                this.interfaceCardSm = 6
                this.interfaceOverviewOperator = false
                this.interfaceOverviewMd = 12
                this.interfaceOverviewLg = 6
            } else if(this.$store.state.user.role == config.user.role.service){

                this.showServicesOrder = true
                this.interfaceCardOperator = false
                this.interfaceCardPoint = false
                this.interfaceOverviewPoint = false
                this.interfaceCardMd = 4
                this.interfaceCardSm = 6

                this.interfaceOverviewOperator = false
                this.interfaceOverviewMd = 6
                this.interfaceOverviewLg = 4
            }

            this.updateData()
            this.timer = setInterval(() => {
                this.updateData()
            }, 20000)
            this.getAveragePriceDelivery()
        },

        beforeDestroy() {
            console.log('!!! beforeDestroy !!!')
            if (this.chart) {
                this.chart.dispose()
            }
        },

        destroyed() {
            console.log('!!! destroyed !!!')
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {},
        methods: {
            getAveragePriceDelivery(){
                let how = ""
                let id = 0
                this.role = this.$store.state.user.role

                switch (this.role) {
                    case 1: //operator
                        how = "operator"
                        id = this.$store.state.user.tid || 0
                        break
                    case 3: //point
                        how = "point"
                        id = this.$store.state.user.point_id || 0
                        break
                    default:
                        break
                }

                // this.$store
                //         .dispatch('get_average_price_delivery', {
                //             how: how, //
                //             id: id,
                //         })
                //         .then(res => {
                //             if (res.data) {
                //                 this.interfaceAveragePriceDeliveryMy = res.data.my
                //                 this.interfaceAveragePriceDeliveryMyCount = res.data.my_count
                //                 this.interfaceAveragePriceDeliveryAll = res.data.all
                //             }
                //             })
            },
            onClickGuest(order) {
                if (order.guest_id) {
                    const name = order.guest_name ? order.guest_name + ' ' : ''
                    this.notifyToast('Гость: ' + name + order.guest_phone, { color: 'info', timeout: 4000 })
                    this.$router.push({ name: 'guests_phone', params: { phone: order.guest_phone } })
                    // this.$router.push({ name: 'guests_phone', params: {phone: order.guest_phone} })
                }
            },
            goToLink(name, all_users) {
                if (!this.userIsPoint || all_users) {
                    this.$router.push({ name })
                }
            },
            isCombinedOrder(item) {
                return item.isCombined
            },
            getServicesCountPercent() {
                return this.serviceOrdersCountToday > 0 ? Math.round((this.serviceOrdersCountComplete / this.serviceOrdersCountToday ) * 100) + '%' : ''
            },
            getOrdersCountPercent() {
                return this.ordersCountToday > 0 ? Math.round((this.ordersCountComplete / this.ordersCountToday) * 100) + '%' : ''
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 15) : guest
            },
            getGuestPhoneName(order) {
                let guest_name = ''
                if (order.guest_id && order.guest_phone) {
                    guest_name += order.guest_phone
                    if (order.guest_name) guest_name += ' - ' + order.guest_name
                } else {
                    if (order.guest) {
                        guest_name = this.getGuestStr(order.guest).substr(0, 24)
                    }
                }
                return guest_name
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            pointNameGet: function (point_id) {
                let point_name = '--'
                const point = this.points.find(p => p.id == point_id)
                if (point) {
                    point_name = point.name
                }
                return point_name
            },
            courierNameGet: function (courier_tid) {
                let courier_name = '--'
                const order = this.orders.find(order => order.courier_tid == courier_tid)
                if (order) {
                    courier_name = this.userIsPoint ? order.courier_name.split(' ')[0] : order.courier_name
                }
                return courier_name
            },
            fillDataOrders() {
                this.chartDataOrders = {
                    labels: this.orderStatusLabels(),
                    disabled: [],
                    hidden:[],
                    datasets: [
                        {
                            backgroundColor: this.orderStatusColorsHtml(),
                            data: this.orderStatuses,
                        },
                    ],
                }
                console.log('this.chartDataOrders', this.chartDataOrders)
            },
            fillDataPoints() {
                this.chartDataPoints = {
                    labels: this.pointsLabels(),
                    disabled: [],
                    hidden:[],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.pointsTodayCounts,
                        },
                    ],
                }
                console.log('this.chartDataPoints', this.chartDataPoints)
            },
            fillDataCouriers() {
                const labels = this.couriersLabels()
                this.chartDataCouriers = {
                    labels: labels[0],
                    disabled: labels[1],
                    hidden:[],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.couriersTodayCounts,
                        },
                    ],
                }
            },
            fillDataServicePoints() {
                this.chartDataServicePoints = {
                    labels: this.pointsLabels(),
                    disabled: [],
                    hidden:[],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.pointsServiceTodayCounts,
                        },
                    ],
                }
                console.log('this.chartDataPoints', this.chartDataServicePoints)
            },

            fillDataServiceOrders() {
                this.chartDataServiceOrders = {
                    labels: this.orderStatusLabels(true),
                    disabled: [],
                    hidden:[false,true,false,false,false,false,true],
                    datasets: [
                        {
                            backgroundColor: this.orderStatusColorsHtml(true),
                            data: this.serviceOrderStatuses,
                        },
                    ],
                }
                console.log('this.chartServiceDataOrders', this.chartDataServiceOrders)
            },

            fillDataWorkers() {
                const labels = this.workersLabels()
                this.chartDataWorkers = {
                    labels: labels[0],
                    disabled: labels[1],
                    hidden:[],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.workersTodayCounts,
                        },
                    ],
                }
            },
            pointsLabels() {
                const res = []
                this.pointsToday.forEach((point_id, i) => {
                    res[i] = this.pointNameGet(point_id).substr(0, 20)
                })
                return res
            },
            couriersLabels() {
                const res = [[], []]
                this.couriersToday.forEach((courier_tid, i) => {
                    res[0][i] = this.courierNameGet(courier_tid).substr(0, 20)
                    res[1][i] = this.couriers.findIndex(courier => courier.tid == courier_tid) == -1
                })
                return res
            },
            workersLabels(){
                const res = [[], []]
                this.workersToday.forEach((worker_tid, i) => {
                    let worker = this.users.find(u=>u.tid==worker_tid)
                    res[0][i] = worker?worker.full_name.substr(0,20):'--'
                    res[1][i] = this.workers.findIndex(worker => worker.tid == worker_tid) == -1
                })
                return res
            },
            orderStatusLabels(service = false) {
                const res = [[], []]
                let len = service ? 8 : 6
                let index = 0;
                for (let i = 0; i < len; i++) {
                    if (service) {
                        if (i == 6) continue
                        else res[i] = this.orderStatusStr(i, service)
                        index++;
                    } else {
                        if (i == 6) break
                        res[i] = this.orderStatusStr(i)
                    } 
                }
                return res
            },
            orderStatusColorsHtml(service = false) {
                if (service) {
                    let colors = []
                    colors[0] = this.orderStatusColorHtml(0)
                    colors[1] = this.orderStatusColorHtml(1)
                    colors[2] = this.orderStatusColorHtml(2)
                    colors[3] = this.orderStatusColorHtml(3)
                    colors[4] = this.orderStatusColorHtml(4)
                    colors[5] = this.orderStatusColorHtml(5)
                    colors[7] = this.orderStatusColorHtml(7)
                    return colors
                } else {
                    return [
                        this.orderStatusColorHtml(0),
                        this.orderStatusColorHtml(1),
                        this.orderStatusColorHtml(2),
                        this.orderStatusColorHtml(3),
                        this.orderStatusColorHtml(4),
                        this.orderStatusColorHtml(5),
                    ]
                }
            },
            updateData() {
                let point_id = 0
                if (this.userIsPoint) {
                    point_id = this.$store.state.user.point_id
                }
                let service_id=0
                if (this.userIsService) {
                    service_id = this.$store.state.user.service_id
                }

                this.$store
                    .dispatch('all_orders_date', {
                        date1: this.getDate1String(),
                        date2: this.getDate2String(),
                        point_id,
                        deliveryTime: true,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.orders = res.data.orders.reverse()
                            this.multiOrders = res.data.multiOrders.reverse()

                            console.log('this.orders', this.orders)
                            console.log('this.multiOrders', this.multiOrders)

                            const ordersComplete = this.orders.map(order => order.state == config.order.status.complete)
                            const multiOrdersComplete = this.multiOrders.map(multiOrder => multiOrder.state === config.order.status.complete)

                            this.ordersCountToday = ordersComplete.length + multiOrdersComplete.length
                            this.orderStatuses[config.order.status.forming] = 0
                            this.orderStatuses[config.order.status.new] = 0
                            this.orderStatuses[config.order.status.passed] = 0
                            this.orderStatuses[config.order.status.way_point] = 0
                            this.orderStatuses[config.order.status.way_guest] = 0
                            this.orderStatuses[config.order.status.complete] = 0

                            // по факту это массив из id точек
                            const pointsTodaySet   = new Set()
                            const couriersTodaySet = new Set()

                            this.orders.forEach(order => {
                                if (order.point_id != null) {
                                    pointsTodaySet.add(order.point_id)
                                }
                                
                                if (order.courier_tid != null) {
                                    couriersTodaySet.add(order.courier_tid)
                                }
                            })

                            this.multiOrders.forEach(multiOrder => {
                                const { orders } = multiOrder

                                orders.forEach(order => {
                                    if (order.point_id !== null) {
                                        pointsTodaySet.add(order.point_id)
                                    }
                                })

                                if (multiOrder.courier_tid != null) {
                                    couriersTodaySet.add(multiOrder.courier_tid)
                                }
                            })

                            this.pointsTodayCount = pointsTodaySet.size
                            this.couriersTodayCount = couriersTodaySet.size
                            this.pointsToday = Array.from(pointsTodaySet)
                            this.couriersToday = Array.from(couriersTodaySet)
                            this.pointsToday.forEach((p_id, i) => {
                                this.pointsTodayCounts[i] = 0
                            })
                            this.couriersToday.forEach((c_tid, i) => {
                                this.couriersTodayCounts[i] = 0
                            })

                            this.orders.forEach(order => {
                                this.orderStatuses[order.status]++
                                this.pointsToday.forEach((p_id, i) => {
                                    if (p_id == order.point_id) this.pointsTodayCounts[i]++
                                    // if (p_id == order.point_id) {
                                    //     this.pointsTodayCounts[i]++
                                    // }
                                })
                                this.couriersToday.forEach((c_tid, i) => {
                                    if (c_tid == order.courier_tid) {
                                        this.couriersTodayCounts[i]++
                                    }
                                })
                            })

                            this.multiOrders.forEach(multiOrder => {
                                const { orders } = multiOrder

                                this.orderStatuses[multiOrder.status]++
                                this.pointsToday.forEach((p_id, i) => {
                                    if (typeof orders.find(e => e.point_id === p_id) !== 'undefined') this.pointsTodayCounts[i]++
                                })
                                this.couriersToday.forEach((c_tid, i) => {
                                    if (c_tid === multiOrder.courier_tid) {
                                        this.couriersTodayCounts[i]++
                                    }
                                })
                            })

                            let mapped = this.pointsTodayCounts.map((el, i) => {
                                return { index: i, value: el }
                            })
                            mapped.sort((a, b) => b.value - a.value)
                            mapped = mapped.slice(0, 20)
                            this.pointsToday = mapped.map(el => this.pointsToday[el.index])
                            this.pointsTodayCounts = mapped.map(el => this.pointsTodayCounts[el.index])

                            mapped = this.couriersTodayCounts.map((el, i) => {
                                return { index: i, value: el }
                            })
                            mapped.sort((a, b) => b.value - a.value)
                            mapped = mapped.slice(0, 20)
                            this.couriersToday = mapped.map(el => this.couriersToday[el.index])
                            this.couriersTodayCounts = mapped.map(el => this.couriersTodayCounts[el.index])

                            this.ordersCountComplete = this.orderStatuses[config.order.status.complete]

                            // let i
                            // for (const o of this.orderStatuses[config.order.status.formed]) {
                            //     console.log(i + 1, o.name, o.id)
                            // }

                            this.profitToday = 0

                            for (var order of this.orders) {
                                if (order.date_complete) {
                                    if (!order.fee_point) order.fee_point = 0
                                    if (!order.fee_courier) order.fee_courier = 0
                                    this.profitToday += parseInt(this.userIsPoint ? order.fee_point : order.fee_courier)
                                }
                            }

                            for (const multiOrder of this.multiOrders) {
                                const { orders } = multiOrder

                                for (const order of orders) {
                                    if (order.date_complete) {
                                        if (!order.fee_point) order.fee_point = 0
                                        if (!order.fee_courier) order.fee_courier = 0
                                        this.profitToday += parseInt(this.userIsPoint ? order.fee_point : order.fee_courier)
                                    }
                                }
                            }

                            // this.$store
                            //     .dispatch('guests_get', {
                            //         date1: this.getDate1String(),
                            //         date2: this.getDate2String(),
                            //         point_id: 0,
                            //     })
                            //     .then(res => {
                            //         if (res.data.success) {
                            //             const guests = res.data.guests
                            //             // console.log('guests', guests)
                            //             for (let order of this.orders) {
                            //                 if (order.guest_id) {
                            //                     const guest = guests.find(g => g.id == order.guest_id)
                            //                     if (guest) {
                            //                         order.guest_name = guest.name
                            //                         order.guest_phone = guest.phone
                            //                     }
                            //                 }
                            //             }
                            //             console.log('guests_get this.orders', this.orders)
                            //         }

                            //     })
                            //     .catch(err => {
                            //         console.log('2 error', err.name, err.message)
                            //     })

                            this.$store
                                .dispatch('all_orders_date', {
                                    date1: this.getDate1String(-1),
                                    date2: this.getDate2String(-1),
                                    point_id,
                                    deliveryTime: true,
                                })
                                .then(res => {
                                    if (res.data.success) {
                                        const orders_yesterday = res.data.orders
                                        const multiOrdersYesterday = res.data.multiOrders

                                        this.profitYesterday = 0
                                        for (var order of orders_yesterday) {
                                            if (order.date_complete) {
                                                if (!order.fee_point) order.fee_point = 0
                                                if (!order.fee_courier) order.fee_courier = 0
                                                this.profitYesterday += parseInt(this.userIsPoint ? order.fee_point : order.fee_courier)
                                            }
                                        }

                                        for (const multiOrder of multiOrdersYesterday) {
                                            const { orders } = multiOrder

                                            for (const order of orders) {
                                                if (order.date_complete) {
                                                    if (!order.fee_point) order.fee_point = 0
                                                    if (!order.fee_courier) order.fee_courier = 0
                                                    this.profitYesterday += parseInt(this.userIsPoint ? order.fee_point : order.fee_courier)
                                                }
                                            }
                                        }

                                        this.profitDiff = this.profitToday - this.profitYesterday
                                        if (this.profitDiff > 0) {
                                            this.profitDiff = '+' + this.profitDiff
                                        }
                                    }
                                })
                        } else {
                            this.ordersCountToday = 0
                            this.ordersCountComplete = 0
                        }
                        this.loaded = true
                        this.fillDataOrders()
                        if (!this.userIsPoint) {
                            this.fillDataPoints()
                        }
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.error(err)
                    })

                    if(!this.userIsOperator||this.userIsOperator&&this.$store.state.settings.services_enable){
                        let options = {
                            date1: this.getDate1String(),
                            date2: this.getDate2String(),
                            deliveryTime: true,
                            service: true
                        }
                        if (this.userIsPoint) {
                            options.point_id = point_id
                        } else if(this.userIsOperator){
                            options.operator_tid=this.$store.state.user.tid
                        }
                        else if(this.userIsService) {
                            options.service_id = service_id
                        }

                        this.$store
                            .dispatch('orders_date', options)
                            .then(res => {
                                if (res.data.success) {
                                    console.log('ORDERS',res.data.orders)
                                    if (res.data.orders.length) {
                                        this.service_orders = res.data.orders.reverse()

                                        console.log('this.service_orders', this.service_orders)
                                        const ordersComplete = this.service_orders.map(order => order.state == config.order.status.complete)
                                        this.serviceOrdersCountToday = ordersComplete.length
                                        this.serviceOrderStatuses[config.order.status.forming] = 0
                                        this.serviceOrderStatuses[config.order.status.new] = 0
                                        this.serviceOrderStatuses[config.order.status.passed] = 0
                                        this.serviceOrderStatuses[config.order.status.way_point] = 0
                                        this.serviceOrderStatuses[config.order.status.complete] = 0
                                        this.serviceOrderStatuses[config.order.status.pending] = 0

                                        let pToday = this.service_orders.map(o => o.point_id)
                                        pToday = this.pointsToday.concat(pToday)
                                        this.pointsToday = pToday.filter((id, index) => pToday.indexOf(id) === index && id != null)
                                        this.pointsTodayCount = this.pointsToday.length;

                                        let pSToday = this.service_orders.map(o => o.point_id)
                                        this.pointsServiceToday = pSToday.filter((id, index) => pSToday.indexOf(id) === index && id != null)

                                        let wToday = this.service_orders.map(o => o.worker_tid)
                                        this.workersToday = wToday.filter((id, index) => wToday.indexOf(id) === index && id != null)

                                        let sToday = this.service_orders.map(o => o.service_id)
                                        this.servicesToday = sToday.filter((id, index) => sToday.indexOf(id) === index && id != null)

                                        this.workersTodayCount = this.workersToday.length
                                        this.servicesTodayCount = this.servicesToday.length

                                        this.pointsServiceTodayCounts = Array(this.pointsServiceToday.length).fill(0)
                                        this.workersTodayCounts = Array(this.workersToday.length).fill(0)

                                        this.service_orders.forEach(order => {
                                            this.serviceOrderStatuses[order.status]++
                                            this.pointsServiceToday.forEach((p_id, i) => {
                                                if (p_id == order.point_id) {
                                                    this.pointsServiceTodayCounts[i]++
                                                }
                                            })
                                            this.workersToday.forEach((w_tid, i) => {
                                                if (w_tid == order.worker_tid) {
                                                    this.workersTodayCounts[i]++
                                                }
                                            })
                                        })

                                        let mapped = this.pointsServiceTodayCounts.map((el, i) => {
                                            return { index: i, value: el }
                                        })
                                        mapped.sort((a, b) => b.value - a.value)
                                        mapped = mapped.slice(0, 20)
                                        this.pointsServiceToday = mapped.map(el => this.pointsServiceToday[el.index])
                                        this.pointsServiceTodayCounts = mapped.map(el => this.pointsServiceTodayCounts[el.index])

                                        mapped = this.workersTodayCounts.map((el, i) => {
                                            return { index: i, value: el }
                                        })
                                        mapped.sort((a, b) => b.value - a.value)
                                        mapped = mapped.slice(0, 20)
                                        this.workersToday = mapped.map(el => this.workersToday[el.index])
                                        this.workersTodayCounts = mapped.map(el => this.workersTodayCounts[el.index])

                                        this.serviceOrdersCountComplete = this.serviceOrderStatuses[config.order.status.complete]

                                        this.serviceProfitToday = 0

                                        this.$store.dispatch('services_get_all')
                                            .then(res => {
                                                if (res.data.success) {
                                                    this.services = res.data.services
                                                    for (let order of this.service_orders) {
                                                        let fs = this.services.find(s => s.id === order.service_id)
                                                        if (order.date_complete) {
                                                            if (fs != undefined && fs.settings?.percent != undefined) {
                                                                let percent = (Number(order.pay_cost) * (Number(fs.settings.percent) / 100.0))
                                                                this.serviceProfitToday += Number(order.pay_cost) - percent
                                                            }
                                                        }
                                                    }
                                                    options = {
                                                        date1: this.getDate1String(-1),
                                                        date2: this.getDate2String(-1),
                                                        deliveryTime: true,
                                                        service: true
                                                    }
                                                    if (this.userIsPoint) {
                                                        options.point_id = point_id
                                                    } else {
                                                        options.service_id = service_id
                                                    }
                                                    this.$store
                                                        .dispatch('orders_date', options)
                                                        .then(res => {
                                                            if (res.data.success) {
                                                                const orders_yesterday = res.data.orders
                                                                console.log('orders_yesterday', orders_yesterday)
                                                                this.serviceProfitYesterday = 0
                                                                for (var order of orders_yesterday) {
                                                                    if (order.date_complete) {
                                                                        let fs = this.services.find(s => s.id == order.service_id)
                                                                        if (fs != undefined && fs.settings?.percent != undefined) {
                                                                            let percent = (Number(order.pay_cost) * (Number(fs.settings.percent) / 100.0))
                                                                            this.serviceProfitYesterday += Number(order.pay_cost) - percent
                                                                        }
                                                                    }
                                                                }
                                                                this.serviceProfitDiff = this.serviceProfitToday - this.serviceProfitYesterday
                                                                if (this.serviceProfitDiff > 0) {
                                                                    this.serviceProfitDiff = '+' + this.serviceProfitDiff
                                                                }
                                                            }
                                                        })
                                                        .catch(err => {
                                                            console.error(err)
                                                        })

                                                }
                                            })
                                            .catch(err => {
                                                console.error(err)
                                            })
                                    } else {
                                        this.serviceOrdersCountToday = 0
                                        this.serviceOrdersCountComplete = 0
                                    }
                                } else {
                                    this.serviceOrdersCountToday = 0
                                    this.serviceOrdersCountComplete = 0
                                }
                                this.loaded = true
                                if (!this.userIsOperator) {
                                    this.fillDataServicePoints()
                                }
                                this.fillDataServiceOrders()
                                this.fillDataWorkers()

                            })
                            .catch(err => {
                                console.error(err)
                            })
                    }
                    

                if (!this.userIsPoint) {
                    this.$store
                        .dispatch('points_get')
                        .then(res => {
                            if (res.data.success) {
                                this.points = res.data.points
                                console.log('points', this.points)
                            }
                            this.fillDataPoints()
                        })
                        .catch(err => {
                            console.error(err)
                        })
                }

                this.$store
                    .dispatch('couriers_get', { online_only: true })
                    .then(res => {
                        if (res.data.success) {
                            this.couriers = res.data.couriers
                           
                            console.log('couriers', this.couriers)
                        }
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.error(err)
                    })

                // this.$store
                //     .dispatch('users_get')
                //     .then(res => {
                //         if (res.data.success) {
                //             this.users = res.data.users
                //             this.couriers = this.users.filter(u => u.enable && u.role == config.user.role.courier)
                //             this.point_users = this.users.filter(u => u.enable && u.point_id && u.role == config.user.role.point)
                //             this.service_users = this.users.filter(u => u.enable&& u.service_id && u.role==config.user.role.service)
                //             this.workers = this.users.filter(u=>u.enable&&u.service_id&&u.role==config.user.role.worker)
                //             if(this.userIsService){
                //                 this.workers = this.workers.filter(w=>w.service_id==this.$store.state.user.service_id)
                //             }
                           
                //             console.log('users', this.users)
                //             console.log('couriers', this.couriers)
                //             console.log('point_users', this.point_users)
                //             console.log('workers', this.workers)
                //             console.log('service_users', this.service_users)
                //         }
                //         this.fillDataCouriers()
                //     })
                //     .catch(err => {
                //         console.error(err)
                //     })
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .analytics
        height 100%
        padding-top 10px !important
        .overview
            max-width 400px
        .text
            padding 0
        .v-card
            cursor default
        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                td      
                    white-space nowrap
                    cursor default
                    .text
                        padding 0 8px

                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor default
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .info-card
            cursor pointer

        .v-list-item__content
            padding 8px 0
            overflow initial

        .no-data
            width 100%
            display block
            text-align center
            color #999
            font-size 13px
            height 30px
            line-height 30px

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline

        .select-mode
            position:fixed
            z-index :10
            
            @media (max-width:1700px)
                position:static
        
</style>
